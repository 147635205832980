import axios from 'axios'

import store from '@/store'
import { Actions as AuthActions } from '@/store/modules/auth'

import router from '@/router'

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || '/',
})

axiosInstance.interceptors.request.use((req) => {
  if (store.state.auth.accessToken) {
    // no-non-null-assertion due to typing bug in axios lib --> https://github.com/axios/axios/issues/4193
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    req.headers!.Authorization = `Bearer ${store.state.auth.accessToken}`
  }
  return req
}, Promise.reject)

axiosInstance.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err?.response?.status === 401) {
      store.dispatch(AuthActions.logout).then(() => {
        if (router.currentRoute.name !== 'login') {
          router.push('/login').catch(() => ({}))
        }
      })

      return null
    } else if (err?.response?.status >= 500) {
      throw new Error(err)
    } else {
      return Promise.reject(err)
    }
  }
)
