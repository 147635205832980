import { useAxios } from '@/composables/useAxios'

import { LoginCredentials, SetPasswordRequest, TokenResponse } from '@/api/types/auth'
import UseAxios from '@/composables/types/useAxios'

const VERSION = 'v1'

export const useGetToken = (): UseAxios<TokenResponse> & {
  getToken: (credentials: LoginCredentials) => Promise<TokenResponse>
} => {
  const axios = useAxios<TokenResponse>({
    method: 'POST',
    url: '/auth/token',
  })

  const getToken = (credentials: LoginCredentials) => axios.exec({ data: credentials })

  return {
    ...axios,
    getToken,
  }
}

export const useRenewToken = (): UseAxios<TokenResponse> & { renewToken: () => Promise<TokenResponse> } => {
  const axios = useAxios<TokenResponse>({
    method: 'POST',
    url: '/auth/renew',
  })

  const renewToken = () => axios.exec()

  return {
    ...axios,
    renewToken,
  }
}

export const useResetPassword = (): UseAxios<void> & { resetPassword: (username: string) => Promise<void> } => {
  const axios = useAxios<void>({
    method: 'POST',
    headers: {
      'content-type': 'text/plain',
    },
    url: `/${VERSION}/reset-credentials`,
  })

  const resetPassword = (username: string) => axios.exec({ data: username })

  return {
    ...axios,
    resetPassword,
  }
}

export const useSetPassword = (): UseAxios<void> & { setPassword: (request: SetPasswordRequest) => Promise<void> } => {
  const axios = useAxios<void>({
    method: 'POST',
    url: `/${VERSION}/set-password`,
  })

  const setPassword = (request: SetPasswordRequest) => axios.exec({ data: request })

  return {
    ...axios,
    setPassword,
  }
}
