import { Rights } from '@/api/types/right'
import { RouteConfigCustom } from '@/router/types/routes'

const DIRECTORY = 'planning'

const planningRoutes: RouteConfigCustom[] = [
  {
    path: `/${DIRECTORY}/capacity`,
    name: `${DIRECTORY}-capacity`,
    component: () => import('@/views/planning/views/capacity/Capacity.vue'),
    meta: {
      title: 'planning.capacity.title',
      authRequired: true,
      permissions: [Rights.CAPACITY_READ],
    },
  },
  {
    path: `/${DIRECTORY}/time-planning`,
    name: `${DIRECTORY}-time-planning`,
    component: () => import('@/views/planning/views/timePlanning/TimePlanning.vue'),
    meta: {
      title: 'planning.timePlanning.title',
      authRequired: true,
      permissions: [Rights.WORKING_DAYS_PLANNED_READ, Rights.PRODUCT_INCREMENT_INTERVAL_READ],
    },
  },
  {
    path: `/${DIRECTORY}/workforce-planning`,
    name: `${DIRECTORY}-workforce-planning`,
    component: () => import('@/views/planning/views/workforcePlanning/WorkforcePlanning.vue'),
    meta: {
      title: 'planning.workforcePlanning.title',
      authRequired: true,
      permissions: [Rights.POSITION_READ_ALL, Rights.POSITION_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/workforce-planning/:positionId`,
    name: 'position-details',
    component: () => import('@/views/planning/views/positionDetails/PositionDetails.vue'),
    meta: {
      title: 'planning.workforcePlanning.positionDetails.title',
      authRequired: true,
      permissions: [Rights.POSITION_READ_ALL, Rights.POSITION_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/position-timeline`,
    name: `${DIRECTORY}-position-timeline`,
    component: () => import('@/views/planning/views/workforcePlanningTimeline/PositionTimeline.vue'),
    meta: {
      title: 'planning.workforcePlanning.positionTimeline.title',
      authRequired: true,
      permissions: [Rights.POSITION_READ_ALL, Rights.POSITION_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/position-timeline/:id`,
    name: `position-timeline`,
    component: () => import('@/views/planning/views/workforcePlanningTimeline/PositionTimeline.vue'),
    meta: {
      title: 'planning.workforcePlanning.positionTimeline.title',
      authRequired: true,
      permissions: [Rights.POSITION_READ_ALL, Rights.POSITION_READ_OWN, Rights.POSITION_SCENARIO_READ],
    },
  },
  {
    path: `/${DIRECTORY}/comparison`,
    name: `comparison`,
    component: () => import('@/views/planning/views/workforcePlanningComparison/Comparison.vue'),
    meta: {
      title: 'planning.workforcePlanning.comparison.title',
      authRequired: true,
      permissions: [
        Rights.POSITION_READ_ALL,
        Rights.POSITION_READ_OWN,
        Rights.POSITION_SCENARIO_READ,
        Rights.ASSIGNMENT_READ_ALL,
        Rights.ASSIGNMENT_READ_OWN,
      ],
    },
  },
]

export { planningRoutes }
