import { Rights } from '@/api/types/right'
import { RouteConfigCustom } from '@/router/types/routes'

const DIRECTORY = 'contract-data'

const contractDataRoutes: RouteConfigCustom[] = [
  {
    path: `/${DIRECTORY}/suppliers`,
    name: `${DIRECTORY}-suppliers`,
    component: () => import('@/views/contractData/views/suppliers/Suppliers.vue'),
    meta: {
      title: 'contractData.suppliers.title',
      authRequired: true,
      i18nKey: 'suppliers',
      permissions: [Rights.SUPPLIER_READ],
    },
  },
  {
    path: `/${DIRECTORY}/contracts`,
    name: `${DIRECTORY}-contracts`,
    component: () => import('@/views/contractData/views/contracts/Contracts.vue'),
    meta: {
      title: 'contractData.contracts.title',
      authRequired: true,
      i18nKey: 'contracts',
      permissions: [Rights.CONTRACT_READ_ALL, Rights.CONTRACT_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/assignments`,
    name: `${DIRECTORY}-assignments`,
    component: () => import('@/views/contractData/views/assignments/Assignments.vue'),
    meta: {
      title: 'contractData.assignments.title',
      authRequired: true,
      i18nKey: 'assignments',
      permissions: [Rights.ASSIGNMENT_READ_ALL, Rights.ASSIGNMENT_READ_OWN],
    },
  },
]

export { contractDataRoutes }
