import { UseAxiosPaginated } from '@/composables/types/useAxiosPaginated'
import { PaginationParams } from '@/composables/types/usePagination'

interface UserRight {
  authority: string
  description?: string
}

enum Rights {
  API_DEVELOPER = 'API_DEVELOPER',
  BASIC_READ = 'BASIC_READ',
  RIGHT_READ = 'RIGHT_READ',
  RIGHT_UPDATE = 'RIGHT_UPDATE',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_READ = 'ROLE_READ',
  ROLE_UPDATE = 'ROLE_UPDATE',
  ROLE_DELETE = 'ROLE_DELETE',
  TRANSLATION_CREATE = 'TRANSLATION_CREATE',
  TRANSLATION_READ = 'TRANSLATION_READ',
  TRANSLATION_UPDATE = 'TRANSLATION_UPDATE',
  TRANSLATION_DELETE = 'TRANSLATION_DELETE',
  USER_CREATE = 'USER_CREATE',
  USER_READ = 'USER_READ',
  USER_UPDATE = 'USER_UPDATE',
  USER_DELETE = 'USER_DELETE',
  PERSON_CREATE = 'PERSON_CREATE',
  PERSON_DELETE = 'PERSON_DELETE',
  PERSON_READ_ALL = 'PERSON_READ_ALL',
  PERSON_READ_OWN = 'PERSON_READ_OWN',
  PERSON_UPDATE = 'PERSON_UPDATE',
  ASSIGNMENT_CREATE = 'ASSIGNMENT_CREATE',
  ASSIGNMENT_DELETE = 'ASSIGNMENT_DELETE',
  ASSIGNMENT_READ_ALL = 'ASSIGNMENT_READ_ALL',
  ASSIGNMENT_READ_OWN = 'ASSIGNMENT_READ_OWN',
  ASSIGNMENT_UPDATE = 'ASSIGNMENT_UPDATE',
  INVENTORY_ITEM_CREATE = 'INVENTORY_ITEM_CREATE',
  INVENTORY_ITEM_DELETE = 'INVENTORY_ITEM_DELETE',
  INVENTORY_ITEM_READ_ALL = 'INVENTORY_ITEM_READ_ALL',
  INVENTORY_ITEM_READ_OWN = 'INVENTORY_ITEM_READ_OWN',
  INVENTORY_ITEM_UPDATE = 'INVENTORY_ITEM_UPDATE',
  NOTE_CREATE = 'NOTE_CREATE',
  NOTE_DELETE = 'NOTE_DELETE',
  NOTE_READ_ALL = 'NOTE_READ_ALL',
  NOTE_READ_OWN = 'NOTE_READ_OWN',
  NOTE_UPDATE = 'NOTE_UPDATE',
  NEWS_READ = 'NEWS_READ',
  NEWS_CREATE = 'NEWS_CREATE',
  NEWS_UPDATE = 'NEWS_UPDATE',
  NEWS_DELETE = 'NEWS_DELETE',
  TENDERING_CREATE = 'TENDERING_CREATE',
  TENDERING_DELETE = 'TENDERING_DELETE',
  TENDERING_READ_ALL = 'TENDERING_READ_ALL',
  TENDERING_READ_OWN = 'TENDERING_READ_OWN',
  TENDERING_UPDATE = 'TENDERING_UPDATE',
  SUPPLIER_CREATE = 'SUPPLIER_CREATE',
  SUPPLIER_DELETE = 'SUPPLIER_DELETE',
  SUPPLIER_READ = 'SUPPLIER_READ',
  SUPPLIER_UPDATE = 'SUPPLIER_UPDATE',
  PROFILE_CREATE = 'PROFILE_CREATE',
  PROFILE_DELETE = 'PROFILE_DELETE',
  PROFILE_READ = 'PROFILE_READ',
  PROFILE_UPDATE = 'PROFILE_UPDATE',
  CONTRACT_CREATE = 'CONTRACT_CREATE',
  CONTRACT_DELETE = 'CONTRACT_DELETE',
  CONTRACT_READ_ALL = 'CONTRACT_READ_ALL',
  CONTRACT_READ_OWN = 'CONTRACT_READ_OWN',
  CONTRACT_UPDATE = 'CONTRACT_UPDATE',
  APPLICANT_CREATE = 'APPLICANT_CREATE',
  APPLICANT_DELETE = 'APPLICANT_DELETE',
  APPLICANT_READ_ALL = 'APPLICANT_READ_ALL',
  APPLICANT_READ_OWN = 'APPLICANT_READ_OWN',
  APPLICANT_UPDATE = 'APPLICANT_UPDATE',
  PROFESSIONAL_UNIT_CREATE = 'PROFESSIONAL_UNIT_CREATE',
  PROFESSIONAL_UNIT_DELETE = 'PROFESSIONAL_UNIT_DELETE',
  PROFESSIONAL_UNIT_READ = 'PROFESSIONAL_UNIT_READ',
  PROFESSIONAL_UNIT_UPDATE = 'PROFESSIONAL_UNIT_UPDATE',
  PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_CREATE = 'PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_CREATE',
  PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_DELETE = 'PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_DELETE',
  PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_READ = 'PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_READ',
  PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_UPDATE = 'PROFESSIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_UPDATE',
  APPLICATION_CREATE = 'APPLICATION_CREATE',
  APPLICATION_DELETE = 'APPLICATION_DELETE',
  APPLICATION_READ_ALL = 'APPLICATION_READ_ALL',
  APPLICATION_READ_OWN = 'APPLICATION_READ_OWN',
  APPLICATION_UPDATE_ALL = 'APPLICATION_UPDATE_ALL',
  APPLICATION_UPDATE_OWN = 'APPLICATION_UPDATE_OWN',
  TASK_CREATE = 'TASK_CREATE',
  TASK_DELETE = 'TASK_DELETE',
  TASK_READ_ALL = 'TASK_READ_ALL',
  TASK_READ_OWN = 'TASK_READ_OWN',
  TASK_UPDATE = 'TASK_UPDATE',
  DEMAND_CREATE = 'DEMAND_CREATE',
  DEMAND_DELETE = 'DEMAND_DELETE',
  DEMAND_READ_ALL = 'DEMAND_READ_ALL',
  DEMAND_READ_OWN = 'DEMAND_READ_OWN',
  DEMAND_UPDATE = 'DEMAND_UPDATE',
  EQUIPMENT_CREATE = 'EQUIPMENT_CREATE',
  EQUIPMENT_DELETE = 'EQUIPMENT_DELETE',
  EQUIPMENT_READ_ALL = 'EQUIPMENT_READ_ALL',
  EQUIPMENT_READ_OWN = 'EQUIPMENT_READ_OWN',
  EQUIPMENT_UPDATE = 'EQUIPMENT_UPDATE',
  WORKING_DAYS_PLANNED_CREATE = 'WORKING_DAYS_PLANNED_CREATE',
  WORKING_DAYS_PLANNED_DELETE = 'WORKING_DAYS_PLANNED_DELETE',
  WORKING_DAYS_PLANNED_READ = 'WORKING_DAYS_PLANNED_READ',
  WORKING_DAYS_PLANNED_UPDATE = 'WORKING_DAYS_PLANNED_UPDATE',
  PRODUCT_INCREMENT_INTERVAL_CREATE = 'PRODUCT_INCREMENT_INTERVAL_CREATE',
  PRODUCT_INCREMENT_INTERVAL_DELETE = 'PRODUCT_INCREMENT_INTERVAL_DELETE',
  PRODUCT_INCREMENT_INTERVAL_READ = 'PRODUCT_INCREMENT_INTERVAL_READ',
  PRODUCT_INCREMENT_INTERVAL_UPDATE = 'PRODUCT_INCREMENT_INTERVAL_UPDATE',
  WORK_PLACE_CREATE = 'WORK_PLACE_CREATE',
  WORK_PLACE_DELETE = 'WORK_PLACE_DELETE',
  WORK_PLACE_READ = 'WORK_PLACE_READ',
  WORK_PLACE_UPDATE = 'WORK_PLACE_UPDATE',
  ORGANIZATIONAL_UNIT_CREATE = 'ORGANIZATIONAL_UNIT_CREATE',
  ORGANIZATIONAL_UNIT_DELETE = 'ORGANIZATIONAL_UNIT_DELETE',
  ORGANIZATIONAL_UNIT_READ = 'ORGANIZATIONAL_UNIT_READ',
  ORGANIZATIONAL_UNIT_UPDATE = 'ORGANIZATIONAL_UNIT_UPDATE',
  CORPORATE_UNIT_CREATE = 'CORPORATE_UNIT_CREATE',
  CORPORATE_UNIT_DELETE = 'CORPORATE_UNIT_DELETE',
  CORPORATE_UNIT_READ = 'CORPORATE_UNIT_READ',
  CORPORATE_UNIT_UPDATE = 'CORPORATE_UNIT_UPDATE',
  ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_CREATE = 'ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_CREATE',
  ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_DELETE = 'ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_DELETE',
  ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_READ = 'ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_READ',
  ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_UPDATE = 'ORGANIZATIONAL_UNIT_MANAGEMENT_TEAM_MEMBER_UPDATE',
  COST_CENTER_CREATE = 'COST_CENTER_CREATE',
  COST_CENTER_DELETE = 'COST_CENTER_DELETE',
  COST_CENTER_READ = 'COST_CENTER_READ',
  COST_CENTER_UPDATE = 'COST_CENTER_UPDATE',
  PSP_ACCOUNT_CREATE = 'PSP_ACCOUNT_CREATE',
  PSP_ACCOUNT_DELETE = 'PSP_ACCOUNT_DELETE',
  PSP_ACCOUNT_READ = 'PSP_ACCOUNT_READ',
  PSP_ACCOUNT_UPDATE = 'PSP_ACCOUNT_UPDATE',
  CORPORATE_IDENTIFIER_CREATE = 'CORPORATE_IDENTIFIER_CREATE',
  CORPORATE_IDENTIFIER_DELETE = 'CORPORATE_IDENTIFIER_DELETE',
  CORPORATE_IDENTIFIER_READ = 'CORPORATE_IDENTIFIER_READ',
  CORPORATE_IDENTIFIER_UPDATE = 'CORPORATE_IDENTIFIER_UPDATE',
  FACILITY_CONSTRUCT_CREATE = 'FACILITY_CONSTRUCT_CREATE',
  FACILITY_CONSTRUCT_DELETE = 'FACILITY_CONSTRUCT_DELETE',
  FACILITY_CONSTRUCT_READ = 'FACILITY_CONSTRUCT_READ',
  FACILITY_CONSTRUCT_UPDATE = 'FACILITY_CONSTRUCT_UPDATE',
  ACCOUNT_CREATE = 'ACCOUNT_CREATE',
  ACCOUNT_DELETE = 'ACCOUNT_DELETE',
  ACCOUNT_READ = 'ACCOUNT_READ',
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  WORKING_HOURS_CREATE = 'WORKING_HOURS_CREATE',
  WORKING_HOURS_DELETE = 'WORKING_HOURS_DELETE',
  WORKING_HOURS_READ_ALL = 'WORKING_HOURS_READ_ALL',
  WORKING_HOURS_READ_OWN = 'WORKING_HOURS_READ_OWN',
  WORKING_HOURS_UPDATE = 'WORKING_HOURS_UPDATE',
  COMPLIANCE_CHECK_CREATE = 'COMPLIANCE_CHECK_CREATE',
  COMPLIANCE_CHECK_DELETE = 'COMPLIANCE_CHECK_DELETE',
  COMPLIANCE_CHECK_READ_ALL = 'COMPLIANCE_CHECK_READ_ALL',
  COMPLIANCE_CHECK_READ_OWN = 'COMPLIANCE_CHECK_READ_OWN',
  COMPLIANCE_CHECK_UPDATE = 'COMPLIANCE_CHECK_UPDATE',
  CAPACITY_CREATE = 'CAPACITY_CREATE',
  CAPACITY_DELETE = 'CAPACITY_DELETE',
  CAPACITY_READ = 'CAPACITY_READ',
  CAPACITY_UPDATE = 'CAPACITY_UPDATE',
  ENUM_ITEM_CREATE = 'ENUM_ITEM_CREATE',
  ENUM_ITEM_READ = 'ENUM_ITEM_READ',
  ENUM_ITEM_DELETE = 'ENUM_ITEM_DELETE',
  DASHBOARD = 'DASHBOARD',
  FILE_READ = 'FILE_READ',
  FILE_CREATE = 'FILE_CREATE',
  FILE_UPDATE = 'FILE_UPDATE',
  FILE_DELETE = 'FILE_DELETE',
  CHECKLIST_READ_ALL = 'CHECKLIST_READ_ALL',
  CHECKLIST_READ_OWN = 'CHECKLIST_READ_OWN',
  CHECKLIST_CREATE = 'CHECKLIST_CREATE',
  CHECKLIST_UPDATE = 'CHECKLIST_UPDATE',
  CHECKLIST_DELETE = 'CHECKLIST_DELETE',
  CHECKLIST_TEMPLATE_READ = 'CHECKLIST_TEMPLATE_READ',
  CHECKLIST_TEMPLATE_CREATE = 'CHECKLIST_TEMPLATE_CREATE',
  CHECKLIST_TEMPLATE_UPDATE = 'CHECKLIST_TEMPLATE_UPDATE',
  CHECKLIST_TEMPLATE_DELETE = 'CHECKLIST_TEMPLATE_DELETE',
  FINANCIAL_READ = 'FINANCIAL_READ',
  POSITION_READ_ALL = 'POSITION_READ_ALL',
  POSITION_READ_OWN = 'POSITION_READ_OWN',
  POSITION_CREATE = 'POSITION_CREATE',
  POSITION_UPDATE = 'POSITION_UPDATE',
  POSITION_DELETE = 'POSITION_DELETE',
  POSITION_SCENARIO_READ = 'POSITION_SCENARIO_READ',
  POSITION_SCENARIO_CREATE = 'POSITION_SCENARIO_CREATE',
  POSITION_SCENARIO_UPDATE = 'POSITION_SCENARIO_UPDATE',
  POSITION_SCENARIO_DELETE = 'POSITION_SCENARIO_DELETE',
  POSITION_TRANSACTION_READ = 'POSITION_TRANSACTION_READ',
  POSITION_TRANSACTION_CREATE = 'POSITION_TRANSACTION_CREATE',
  POSITION_TRANSACTION_DELETE = 'POSITION_TRANSACTION_DELETE',
  EXPORT_FILE_READ = 'EXPORT_FILE_READ',
  EXPORT_FILE_DOWNLOAD = 'EXPORT_FILE_DOWNLOAD',
  EXPORT_FILE_DELETE = 'EXPORT_FILE_DELETE',
  PERSON_DETAILS_READ = 'PERSON_DETAILS_READ',
}

type UseGetRights = {
  getRights: (paginationParams?: PaginationParams) => Promise<UserRight[]>
} & UseAxiosPaginated<UserRight>

export { UserRight, Rights, UseGetRights }
