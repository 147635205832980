import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { i18n } from '@/plugins/i18n'

import store, { useNotify } from '@/store'

const { addNotification } = useNotify()

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    trackComponents: true,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.3,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.5,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.5,

    hooks: ['create', 'destroy', 'activate', 'mount', 'update'],
    integrations: (integrations) => {
      return [
        ...integrations.filter((integration) => integration.name !== 'Dedupe'),
        new Sentry.BrowserTracing(),
        new Sentry.Replay({
          maskAllText: false,
          maskAllInputs: false,
          blockAllMedia: true,
        }),
      ] // filter dedupe lib to not skip duplicate errors
    },
    beforeSend(event) {
      const currentUser = store.state.auth.currentUser

      event.user = {
        ...event.user,
        ...{
          email: currentUser?.email || '',
          id: currentUser?.id.toString() || '',
        },
      }

      addNotification({
        type: 'error',
        text: `${i18n.t('misc.genericServerSideErrorMessage')}`,
        timeout: 7000,
        sentryEventId: event.event_id,
      })

      return event
    },
  })
}
