import { Rights } from '@/api/types/right'
import { RouteConfigCustom } from '@/router/types/routes'

const DIRECTORY = 'base-data'

const baseDataRoutes: RouteConfigCustom[] = [
  {
    path: `/${DIRECTORY}/budget`,
    name: 'base-data-budget',
    component: () => import('@/views/baseData/views/budget/Budget.vue'),
    meta: {
      title: 'baseData.budget.title',
      authRequired: true,
      permissions: [
        Rights.COST_CENTER_READ,
        Rights.PSP_ACCOUNT_READ,
        Rights.CORPORATE_IDENTIFIER_READ,
        Rights.FACILITY_CONSTRUCT_READ,
        Rights.ACCOUNT_READ,
      ],
    },
  },
  {
    path: `/${DIRECTORY}/profiles`,
    name: 'base-data-profiles',
    component: () => import('@/views/baseData/views/profiles/Profiles.vue'),
    meta: {
      title: 'baseData.profiles.title',
      authRequired: true,
      permissions: [Rights.PROFILE_READ],
    },
  },
  {
    path: `/${DIRECTORY}/inventory-items`,
    name: 'base-data-inventory-items',
    component: () => import('@/views/baseData/views/inventoryItems/InventoryItems.vue'),
    meta: {
      title: 'baseData.inventoryItems.title',
      authRequired: true,
      permissions: [Rights.INVENTORY_ITEM_READ_ALL, Rights.INVENTORY_ITEM_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/work-places`,
    name: 'base-data-work-places',
    component: () => import('@/views/baseData/views/workPlaces/WorkPlaces.vue'),
    meta: {
      title: 'baseData.workPlaces.title',
      authRequired: true,
      permissions: [Rights.WORK_PLACE_READ],
    },
  },
  {
    path: `/${DIRECTORY}/corporate-structure`,
    name: 'base-data-corporate-structure',
    component: () => import('@/views/baseData/views/corporateStructure/CorporateStructure.vue'),
    meta: {
      title: 'baseData.corporateStructure.title',
      authRequired: true,
      permissions: [Rights.ORGANIZATIONAL_UNIT_READ, Rights.CORPORATE_UNIT_READ],
      i18nKey: 'corporateStructure',
    },
  },
  {
    path: `/${DIRECTORY}/prof-units`,
    name: 'base-data-prof-units',
    component: () => import('@/views/baseData/views/profUnits/ProfUnits.vue'),
    meta: {
      title: 'baseData.profUnits.title',
      authRequired: true,
      permissions: [Rights.PROFESSIONAL_UNIT_READ],
      i18nKey: 'profUnits',
    },
  },
  {
    path: `/${DIRECTORY}/checklist-templates`,
    name: 'base-data-checklists-templates',
    component: () => import('@/views/baseData/views/checklistTemplates/ChecklistTemplates.vue'),
    meta: {
      title: 'baseData.checklistTemplates.title',
      authRequired: true,
      permissions: [Rights.CHECKLIST_TEMPLATE_READ],
    },
  },
  {
    path: `/${DIRECTORY}/enum-items`,
    name: 'base-data-enum-items',
    component: () => import('@/views/baseData/views/enumItems/EnumItems.vue'),
    meta: {
      title: 'baseData.enumItems.title',
      authRequired: true,
      permissions: [Rights.ENUM_ITEM_READ],
    },
  },
]

export { baseDataRoutes }
