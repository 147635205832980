var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    style: {
      background: _vm.$vuetify.theme.themes['light'].background
    }
  }, [_c('Notify'), _c(_vm.layout, {
    tag: "component"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }