import { Rights } from '@/api/types/right'
import { RouteConfigCustom } from '@/router/types/routes'

const DIRECTORY = 'acquisition'

const acquisitionRoutes: RouteConfigCustom[] = [
  {
    path: `/${DIRECTORY}/applications`,
    name: `${DIRECTORY}-applications`,
    component: () => import('@/views/acquisition/views/applications/Applications.vue'),
    meta: {
      title: 'acquisition.applications.title',
      authRequired: true,
      i18nKey: 'applications',
      permissions: [Rights.APPLICATION_READ_ALL, Rights.APPLICATION_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/applicants`,
    name: `${DIRECTORY}-applicants`,
    component: () => import('@/views/acquisition/views/applicants/Applicants.vue'),
    meta: {
      title: 'acquisition.applicants.title',
      authRequired: true,
      i18nKey: 'applicants',
      permissions: [Rights.APPLICANT_READ_ALL, Rights.APPLICANT_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/demands`,
    name: `${DIRECTORY}-demands`,
    component: () => import('@/views/acquisition/views/demands/Demands.vue'),
    meta: {
      title: 'acquisition.demands.title',
      authRequired: true,
      i18nKey: 'demands',
      permissions: [Rights.DEMAND_READ_ALL, Rights.DEMAND_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/tenderings`,
    name: `${DIRECTORY}-tenderings`,
    component: () => import('@/views/acquisition/views/tenderings/Tenderings.vue'),
    meta: {
      title: 'contractData.tenderings.title',
      authRequired: true,
      i18nKey: 'tenderings',
      permissions: [Rights.TENDERING_READ_ALL, Rights.TENDERING_READ_OWN],
    },
  },
]

export { acquisitionRoutes }
