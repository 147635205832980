import vuexStore from '@/store'

import { Actions, Notification } from './index'

import { UseNotify } from '@/store/modules/notify/types'

export const useNotify = (store = vuexStore): UseNotify => {
  const addNotification = (notification: Notification): Promise<number> => {
    return store.dispatch(Actions.addNotification, notification)
  }
  const deleteNotification = (id: number) => {
    store.dispatch(Actions.deleteNotification, id)
  }
  const shiftNotifications = () => {
    store.dispatch(Actions.shiftNotifications)
  }

  return {
    addNotification,
    deleteNotification,
    shiftNotifications,
  }
}
