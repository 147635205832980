import axios from 'axios'
import { Module, GetterTree, MutationTree, ActionTree } from 'vuex'

import { RootState } from '@/store'

import { loadLanguageAsync } from '@/plugins/i18n'

const localAxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || '/',
})
export interface ImplementetLocales {
  [key: string]: {
    language: string
    country: string
  }
}

export interface LocaleState {
  isLocaleInitialized: boolean
  defaultLocale: string
  currentLocale: string
  implementedLocales: string[]
}

export const state: LocaleState = {
  isLocaleInitialized: false,
  defaultLocale: 'de',
  currentLocale: 'de',
  implementedLocales: ['de'],
}

export enum Getters {}

export const getters: GetterTree<LocaleState, RootState> = {}

enum Mutations {
  SET_LOCALE_INITIALIZED = 'SET_LOCALE_INITIALIZED',
  SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE',
  SET_IMPLEMENTED_LOCALES = 'SET_IMPLEMENTED_LOCALES',
  ADD_IMPLEMENTED_LOCALE = 'ADD_IMPLEMENTED_LOCALE',
}

export const mutations: MutationTree<LocaleState> = {
  SET_LOCALE_INITIALIZED(state, payload: boolean) {
    state.isLocaleInitialized = payload
  },
  SET_CURRENT_LOCALE(state, lang: string) {
    state.currentLocale = lang
  },
  SET_IMPLEMENTED_LOCALES(state, languages: string[]) {
    state.implementedLocales = languages
  },
  ADD_IMPLEMENTED_LOCALE(state, locale: string) {
    state.implementedLocales.push(locale)
  },
}

export enum Actions {
  setCurrentLocale = 'locale/setCurrentLocale',
  addLocale = 'locale/addLocale',
  init = 'locale/init',
}

export const actions: ActionTree<LocaleState, RootState> = {
  setCurrentLocale({ commit }, lang: string) {
    loadLanguageAsync(lang).then(() => {
      commit(Mutations.SET_CURRENT_LOCALE, lang)
      localStorage.setItem('lang', lang)
    })
  },
  addLocale({ commit }, locale: string) {
    commit(Mutations.ADD_IMPLEMENTED_LOCALE, locale)
  },
  async init({ commit }) {
    const locales = await localAxiosInstance
      .get('/v1/translations/locales')
      .then((response) => response.data)
      .catch((error) => {
        throw error
      })

    commit(Mutations.SET_IMPLEMENTED_LOCALES, Object.keys(locales))

    const lang = localStorage.getItem('lang') || navigator.language
    if (Object.keys(locales).includes(lang)) {
      commit(Mutations.SET_CURRENT_LOCALE, lang)
    }

    commit(Mutations.SET_LOCALE_INITIALIZED, true)
  },
}

const locale: Module<LocaleState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default locale
