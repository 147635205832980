import { Rights } from '@/api/types/right'
import { RouteConfigCustom } from '@/router/types/routes'

const DIRECTORY = 'administration'

const administrationRoutes: RouteConfigCustom[] = [
  {
    path: `/${DIRECTORY}/equipments`,
    name: 'administration-equipment',
    component: () => import('@/views/administration/views/equipment/Equipment.vue'),
    meta: {
      title: 'administration.equipments.title',
      authRequired: true,
      i18nKey: 'equipments',
      permissions: [Rights.EQUIPMENT_READ_ALL, Rights.EQUIPMENT_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/notes`,
    name: 'administration-notes',
    component: () => import('@/views/administration/views/notes/Notes.vue'),
    meta: {
      title: 'administration.notes.title',
      authRequired: true,
      i18nKey: 'notes',
      permissions: [Rights.NOTE_READ_ALL, Rights.NOTE_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/tasks`,
    name: 'administration-tasks',
    component: () => import('@/views/administration/views/tasks/Tasks.vue'),
    meta: {
      title: 'administration.tasks.title',
      authRequired: true,
      i18nKey: 'tasks',
      permissions: [Rights.TASK_READ_ALL, Rights.TASK_READ_OWN],
    },
  },
  {
    path: `/${DIRECTORY}/export-files`,
    name: 'administration-export-files',
    component: () => import('@/views/administration/views/exportFiles/ExportFiles.vue'),
    meta: {
      title: 'administration.exportFiles.title',
      authRequired: true,
      permissions: [Rights.EXPORT_FILE_READ],
    },
  },
]

export { administrationRoutes }
