import { useAxios } from '@/composables/useAxios'
import { UserUpdate } from '@/api/types/user'
import { UseChangePassword, UseGetCurrentUser, UseUpdateCurrentUser } from '@/api/types/me'
import { PromiseType } from '@/utils/types/PromiseType'

const VERSION = 'v1'
const RESOURCE = 'users/me'

export const useGetCurrentUser = (): UseGetCurrentUser => {
  const axios = useAxios<PromiseType<ReturnType<UseGetCurrentUser['getCurrentUser']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const getCurrentUser = () => axios.exec({})

  return {
    ...axios,
    getCurrentUser,
  }
}

export const useUpdateCurrentUser = (): UseUpdateCurrentUser => {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateCurrentUser['updateCurrentUser']>>>({
    method: 'PUT',
    url: `/${VERSION}/${RESOURCE}`,
  })

  const updateCurrentUser = (user: UserUpdate) => axios.exec({ data: user })

  return {
    ...axios,
    updateCurrentUser,
  }
}

export const useChangePassword = (): UseChangePassword => {
  const axios = useAxios<PromiseType<ReturnType<UseChangePassword['changePassword']>>>({
    method: 'PUT',
    url: `/${VERSION}/${RESOURCE}/password`,
  })

  const changePassword = (newPassword: string, oldPassword: string) =>
    axios.exec({
      data: {
        password: newPassword,
        verification: oldPassword,
      },
    })

  return {
    ...axios,
    changePassword,
  }
}
