/* eslint-disable @typescript-eslint/no-non-null-assertion */
import vuexStore from '@/store'

import { Actions } from './index'

import { UseLocaleActions } from '@/store/modules/locale/types'

export const useLocaleActions = (store = vuexStore): UseLocaleActions => {
  const setCurrentLocale = (locale: string) => {
    store.dispatch(Actions.setCurrentLocale, locale)
  }

  const addLocale = (locale: string) => {
    store.dispatch(Actions.addLocale, locale)
  }

  const init = () => {
    return store.dispatch(Actions.init)
  }

  return {
    setCurrentLocale,
    addLocale,
    init,
  }
}
