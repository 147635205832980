import './compositionApiInstall'

import Vue from 'vue'

import App from './App.vue'

import store from '@/store'

import Router from 'vue-router'
import router from './router'
Vue.use(Router)

// Globally register all `base`-prefixed components
import '@/components/base/globals'

// Plugins
import { i18n } from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'
import '@/plugins/sentry'

import '@/assets/styles/custom.scss'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
