import { Rights } from '@/api/types/right'
import { RouteConfigCustom } from '@/router/types/routes'

const adminRoutes: RouteConfigCustom[] = [
  {
    path: '/admin/users',
    name: 'adminUsers',
    component: () => import('@/views/admin/users/Users.vue'),
    meta: {
      title: 'admin.users.title',
      authRequired: true,
      permissions: [Rights.USER_READ],
    },
  },
  {
    path: '/admin/rights',
    name: 'adminRights',
    component: () => import('@/views/admin/rights/AdminRightsView.vue'),
    meta: {
      title: 'admin.rights.title',
      authRequired: true,
      permissions: [Rights.RIGHT_READ],
    },
  },
  {
    path: '/admin/roles',
    name: 'adminRoles',
    component: () => import('@/views/admin/roles/AdminRolesView.vue'),
    meta: {
      title: 'admin.roles.title',
      authRequired: true,
      permissions: [Rights.ROLE_READ],
    },
  },
  {
    path: '/admin/translations',
    name: 'adminTranslations',
    component: () => import('@/views/admin/translations/AdminTranslationsView.vue'),
    meta: {
      title: 'admin.translations.title',
      authRequired: true,
      permissions: [Rights.TRANSLATION_READ],
    },
  },
]

export { adminRoutes }
