import { Rights } from '@/api/types/right'
import { RouteConfigCustom } from '@/router/types/routes'

const personRoutes: RouteConfigCustom[] = [
  {
    path: '/persons/list',
    name: 'persons',
    component: () => import('@/views/persons/views/personsList/PersonsList.vue'),
    meta: {
      title: 'persons.persons.title',
      authRequired: true,
      permissions: [Rights.PERSON_READ_ALL, Rights.PERSON_READ_OWN],
    },
  },
  {
    path: '/persons/checklist',
    name: 'persons-checklist',
    component: () => import('@/views/persons/views/personChecklists/PersonChecklists.vue'),
    meta: {
      title: 'persons.checklists.title',
      authRequired: true,
      permissions: [Rights.CHECKLIST_READ_OWN, Rights.CHECKLIST_READ_ALL, Rights.CHECKLIST_TEMPLATE_READ],
    },
  },
  {
    path: '/persons/:id',
    name: 'person-profile',
    component: () => import('@/views/persons/views/personProfile/PersonProfile.vue'),
    meta: {
      title: 'persons.profile.title',
      authRequired: true,
      i18nKey: 'contracts',
      permissions: [Rights.PERSON_READ_ALL, Rights.PERSON_READ_OWN],
    },
  },
]

export { personRoutes }
