import { RouteConfig } from 'vue-router'

import { adminRoutes } from '@/views/admin/adminRoutes'
import { personRoutes } from '@/views/persons/personRoutes'
import { baseDataRoutes } from '@/views/baseData/baseDataRoutes'
import { contractDataRoutes } from '@/views/contractData/contractDataRoutes'
import { administrationRoutes } from '@/views/administration/administrationRoutes'
import { acquisitionRoutes } from '@/views/acquisition/acquisitionRoutes'
import { planningRoutes } from '@/views/planning/planningRoutes'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      title: 'home.title',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/LoginView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/ContactView.vue'),
  },
  {
    path: '/signUp',
    name: 'signUp',
    component: () => import('@/views/signUp/SignUpView.vue'),
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    props: true,
    component: () => import('@/views/password/ResetPasswordView.vue'),
  },
  {
    path: '/setPassword',
    name: 'setPassword',
    props: (route) => ({ token: route.query.token }),
    component: () => import('@/views/password/SetPasswordView.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/ProfileView.vue'),
    meta: {
      title: 'profile.title',
      authRequired: true,
    },
  },
  {
    path: '/about',
    name: 'aboutUs',
    component: () => import('@/views/legal/AboutUsView.vue'),
  },
  {
    path: '/privacyPolicy',
    name: 'privacyPolicy',
    component: () => import('@/views/legal/PrivacyPolicyView.vue'),
  },
  ...adminRoutes,
  ...personRoutes,
  ...baseDataRoutes,
  ...contractDataRoutes,
  ...administrationRoutes,
  ...acquisitionRoutes,
  ...planningRoutes,
  {
    path: '*',
    redirect: '/',
  },
]

export default routes
