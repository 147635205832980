var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-snackbar', _vm._b({
    attrs: {
      "color": _vm.determineNotifyColor(_vm.notification.type),
      "app": "",
      "center": "",
      "top": "",
      "vertical": !!_vm.notification.sentryEventId,
      "transition": "slide-y-transition",
      "timeout": _vm.notification.timeout
    },
    on: {
      "input": _vm.onHideSnackbar
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          class: [_vm.notification.type + '--text', 'text--darken-4', 'rounded-lg'],
          attrs: {
            "elevation": "0",
            "text": ""
          },
          on: {
            "click": _vm.onHideSnackbar
          }
        }, 'v-btn', attrs, false), [_vm._v(" " + _vm._s(_vm.$t('form.action.close')) + " ")]), !!_vm.notification.sentryEventId ? _c('v-btn', _vm._b({
          staticClass: "rounded-lg ml-4",
          attrs: {
            "color": "".concat(_vm.determineNotifyColor(_vm.notification.type), " darken-4"),
            "elevation": "0"
          },
          on: {
            "click": function click($event) {
              _vm.openFeedbackDialog(_vm.notification.sentryEventId);

              _vm.onHideSnackbar();
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Feedback ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.notification.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.notification, "visible", $$v);
      },
      expression: "notification.visible"
    }
  }, 'v-snackbar', _vm.notification.attrs, false), [_c('span', {
    class: [_vm.notification.type + '--text', 'text--darken-4']
  }, [_vm._v(" " + _vm._s(_vm.notification.text) + " ")])]), _c('v-dialog', {
    attrs: {
      "content-class": "rounded-lg elevation-2",
      "width": "600",
      "scrollable": ""
    },
    model: {
      value: _vm.showFeedbackDialog,
      callback: function callback($$v) {
        _vm.showFeedbackDialog = $$v;
      },
      expression: "showFeedbackDialog"
    }
  }, [_vm.showFeedbackDialog ? _c('SentryFeedbackDialog', {
    attrs: {
      "event-id": _vm.eventId
    },
    on: {
      "close": function close($event) {
        _vm.showFeedbackDialog = false;
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }