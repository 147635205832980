
import { defineComponent, computed } from '@vue/composition-api'

import { useAuthGetters } from '@/store'

const Default = () => import('@/layouts/default/DefaultLayout.vue')
const Public = () => import('@/layouts/public/PublicLayout.vue')

import Notify from '@/components/notify/Notify.vue'

export default defineComponent({
  name: 'App',
  components: {
    Notify,
  },
  setup: (_, { root }) => {
    const { isAuthenticated } = useAuthGetters()

    const layout = computed(() =>
      root.$route?.meta?.layout ? root.$route.meta.layout : isAuthenticated.value ? Default : Public
    )
    return {
      layout,
    }
  },
})
