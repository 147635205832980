/* eslint-disable @typescript-eslint/no-non-null-assertion */
import vuexStore from '@/store'
import { computed } from '@vue/composition-api'

import { User } from '@/api/types/user'
import { LoginCredentials } from '@/api/types/auth'
import { Rights } from '@/api/types/right'

import { Actions, Getters } from './index'
import { UseAuthActions, UseAuthGetters } from '@/store/modules/auth/types'

export const useAuthGetters = (store = vuexStore): UseAuthGetters => {
  const userRights = computed((): string[] => store.getters[Getters.userRights])

  const isAuthenticated = computed((): boolean => store.getters[Getters.isAuthenticated])

  const hasRight = computed((): ((right: Rights) => boolean) => store.getters[Getters.hasRight])

  const currentUser = computed(() => store.getters[Getters.currentUser])

  return {
    userRights,
    isAuthenticated,
    hasRight,
    currentUser,
  }
}

export const useAuthActions = (store = vuexStore): UseAuthActions => {
  const getCurrentUser = (): Promise<void> => {
    return store.dispatch(Actions.getCurrentUser)
  }

  const setCurrentUser = (user: User | null): void => {
    store.dispatch(Actions.setCurrentUser, user)
  }

  const login = (credentials: LoginCredentials): Promise<void> => {
    return store.dispatch(Actions.login, credentials)
  }

  const logout = (): Promise<void> => {
    return store.dispatch(Actions.logout)
  }

  const setToken = (token: string): Promise<void> => {
    return store.dispatch(Actions.setToken, token)
  }

  const init = () => {
    store.dispatch(Actions.init)
  }

  return {
    getCurrentUser,
    setCurrentUser,
    setToken,
    login,
    logout,
    init,
  }
}
