import { computed, ComputedRef } from '@vue/composition-api'
import Router from 'vue-router'

import { i18n, loadLanguageAsync } from '@/plugins/i18n'

import store, { useLocaleActions } from '@/store'
import { useAuthGetters, useAuthActions } from '@/store'

import { capitalize } from '@/utils/capitalize'

import { User } from '@/api/types/user'
import { Rights } from '@/api/types/right'

import routes from './routes'

const PAGE_TITLE_PREFIX = capitalize(process.env.VUE_APP_NAME || '')

const router = new Router({
  base: process.env.VUE_APP_BASE_URL,
  mode: 'history',
  routes,
})

router.beforeEach(async (to, from, next) => {
  if (!store) return

  const { isAuthenticated, userRights } = useAuthGetters(store)

  const { getCurrentUser, logout, setToken } = useAuthActions(store)

  const { init } = useLocaleActions()

  const isLocaleInitialized: ComputedRef<boolean> = computed(() => store.state.locale.isLocaleInitialized)
  const currentLanguage: ComputedRef<string> = computed(() => store.state.locale.currentLocale)
  const currentUser: ComputedRef<User | null> = computed(() => store.state.auth.currentUser)

  if (to.path === '/' && to.query.token && typeof to.query.token === 'string') {
    await setToken(to.query.token)
  }

  if (!isLocaleInitialized.value) {
    await init()
  }

  // Fetch the user on application relaod
  if (isAuthenticated.value && !currentUser.value) {
    getCurrentUser().then(() => {
      checkPermission()
    })
  } else {
    checkPermission()
  }

  function checkPermission() {
    const authRequired = to.matched.some((route) => route.meta.authRequired)

    //If no auth required just goNext()
    if (!authRequired || process.env.VUE_APP_DISABLE_AUTH) {
      goNext()
    }
    //Auth required
    else {
      //Auth required but not authenticated
      if (!isAuthenticated.value) {
        logout()
        return
      }
      //Check if user has required rights
      else {
        if (!to?.meta?.permissions?.length) {
          goNext()
        } else if (
          to.meta.permissions.some((permission: keyof typeof Rights) => userRights.value.includes(permission))
        ) {
          goNext()
        } else goNext('/')
      }
    }
  }

  function goNext(newLocation: string | void) {
    loadLanguageAsync(currentLanguage.value)
      .then(() => next(newLocation))
      .catch(() => ({}))
  }
})

router.afterEach((to) => {
  document.title = PAGE_TITLE_PREFIX + (to?.meta?.title ? ` - ${i18n.t(to.meta.title)}` : '')
})

export default router
