import { Ref, ref } from '@vue/composition-api'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

import { axiosInstance } from '@/plugins/axios'

import UseAxios from '@/composables/types/useAxios'

const useAxios = <T = unknown>(baseCfg: AxiosRequestConfig = {}, instance = axiosInstance): UseAxios<T> => {
  const status = ref(0)
  const statusText = ref('')

  const data: Ref<T | null> = ref(null)

  const isLoading = ref(false)

  const exec = async (cfg: AxiosRequestConfig = {}) => {
    isLoading.value = true
    try {
      const result: AxiosResponse<T> = await instance.request({ ...baseCfg, ...cfg })
      data.value = result?.data
      status.value = result?.status
      return result?.data
    } catch (error) {
      return Promise.reject(error)
    } finally {
      isLoading.value = false
    }
  }

  return {
    data,
    status,
    statusText,
    isLoading,
    exec,
  }
}

export { useAxios }
