
import { computed, defineComponent, ref } from '@vue/composition-api'

import { useNotify } from '@/store'
import { NotificationObject } from '@/store/modules/notify'

export default defineComponent({
  name: 'Notify',
  components: {
    SentryFeedbackDialog: () => import('@/components/notify/SentryFeedbackDialog.vue'),
  },
  setup: (_, { root }) => {
    const { shiftNotifications } = useNotify()
    const notifications = computed((): NotificationObject[] => root.$store.state.notify.notifications)

    const onHideSnackbar = () => {
      notifications.value[0].visible = false
      setTimeout(() => {
        shiftNotifications()
      }, 500)
    }

    const notification = computed<NotificationObject | null>(
      () => notifications.value[0] || { visible: false, timeout: 5000, id: 9000, text: '' }
    )

    function determineNotifyColor(color: string) {
      let determinedColor: string

      switch (color) {
        case 'success':
          determinedColor = 'successLighter'
          break
        case 'warning':
          determinedColor = 'warningLighter'
          break
        case 'error':
          determinedColor = 'errorLighter'
          break
        default:
          determinedColor = 'infoLighter'
      }

      return determinedColor
    }

    // Sentry Feedback logic
    const showFeedbackDialog = ref(false)
    const eventId = ref('')
    const openFeedbackDialog = (event: string) => {
      eventId.value = event

      showFeedbackDialog.value = true
    }

    return {
      notification,
      onHideSnackbar,
      showFeedbackDialog,
      openFeedbackDialog,
      eventId,
      determineNotifyColor,
    }
  },
})
