import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
})

const vuetify = new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#1455C0',
        onPrimary: '#FFFFFF',
        secondary: '#FFFFFF',
        onSecondary: '#333333',
        accent: '#82B1FF',
        error: '#FF5252',
        errorLighter: '#fee6e6',
        info: '#2196F3',
        infoLighter: '#e8f5ff',
        success: '#4CAF50',
        successLighter: '#e2f3e5',
        warning: '#FFC107',
        warningLighter: '#ffffdc',
        background: '#DDE3E6',
        hover: '#e6edf8',

        dbOrange: '#F39200',
        dbRed: '#EC0016',
        dbCyan: '#309FD1',
        dbTurquoise: '#00A099',
        dbGreen: '#408335',
        dbLightGreen: '#9FD45F',
      },
    },
  },
})

export default vuetify
